import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
import Slider, { Slide } from '~/components/elements/Slider'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentLinkBlocks({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  return (
    <section data-component="ContentLinkBlocks" id={fields.sectionId ?? undefined} className="mb-12 xl:mb-0">
      <div className="container">
        <div className="xl:w-3/4 ml-auto">
          <Content className="children-headings:text-3xl xl:children-headings:text-7xl children-headings:text-eo-buizel children-headings:mb-4">
            {fields.description}
          </Content>
        </div>
        <div className="hidden xl:grid grid-cols-5 gap-4 mt-12">
          {fields.linkBlocks?.map((edge) => {
            return (
              <div key={edge?.link?.title} className="col-span-1 bg-white shadow-xl rounded-2xl">
                <LosseLink
                  to={edge?.link?.url}
                  target={edge?.link?.target || '_self'}
                  className="aspect-square flex flex-col items-center px-4 py-3"
                >
                  <div className="flex flex-1 items-center">
                    <LossePlaatjie maxwidth={112} src={edge?.image} className="w-28 h-28" />
                  </div>
                  <div className="btn--white--transparent leading-5">{edge?.link?.title}</div>
                </LosseLink>
              </div>
            )
          })}
        </div>
        <div className="xl:hidden mt-8 -mr-4 md:max-xl:-mr-12">
          <Slider
            breakpoints={{
              0: {
                slidesPerView: 1.5,
                spaceBetween: 20
              },
              768: {
                slidesPerView: 2.5,
                spaceBetween: 25
              },
              1024: {
                slidesPerView: 3.5,
                spaceBetween: 30
              }
            }}
            className="!pr-4 md:!pr-12 lg:max-xl:!-mr-16"
          >
            {fields.linkBlocks?.map((edge) => {
              return (
                <Slide key={edge?.link?.title} className="!h-auto mb-5">
                  <div className="aspect-square flex flex-col items-center bg-white shadow-md rounded-2xl px-4 pb-4">
                    <div className="flex flex-1 justify-center items-center">
                      <LossePlaatjie maxwidth={112} src={edge?.image} className="w-28 h-28" />
                    </div>
                    <LosseLink src={edge?.link} className="btn--white--transparent" />
                  </div>
                </Slide>
              )
            })}
          </Slider>
        </div>
        <div className="flex flex-col items-center justify-center mt-12">
          {fields.links?.map((edge) => {
            return (
              <div key={edge?.link?.title}>
                <span className="inline-block text-xl text-eo-beautifly text-center font-semibold">{edge?.microcopy}</span>
                <div className="flex justify-center mt-4">
                  <LosseLink src={edge?.link} className="btn--blue" />
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}
