import clsx from 'clsx'
import { useState, useEffect, useRef } from 'react'
import Content from '~/components/elements/Content'
import Form from '~/components/elements/Form'
import type { Page_Flexcontent_Flex_Form } from '~/graphql/types'

export default function FormNewsletter({ fields }: { fields: Page_Flexcontent_Flex_Form }) {
  const [isVisible, setIsVisible] = useState(false)
  const formRef = useRef<HTMLDivElement | null>(null)
  const buttonRef = useRef<HTMLButtonElement | null>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        formRef.current &&
        !formRef.current.contains(event.target as Node) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target as Node)
      ) {
        setIsVisible(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const closeForm = () => {
    setIsVisible(false)
  }

  return (
    <section className="fixed z-[99] sm:right-0 max-sm:left-3 sm:top-[175px] bottom-3" data-component="FormNewsletter">
      <button
        ref={buttonRef}
        className="sm:rotate-[-90deg] smooth items-center origin-bottom-right font-light uppercase text-white bg-eo-buizel sm:text-[20px] text-[18px] sm:pt-[18px] py-2 sm:px-[20px] sm:pb-[12px] px-[12px] pb-[8px] pt-[10px] drop-shadow-md flex md:rounded-t-2xl sm:button-hover-accent sm:hover:bg-hover"
        onClick={() => setIsVisible(!isVisible)}
      >
        <div className="rotate-[90deg]">
          <svg className="sm:hidden" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <g clipPath="url(#clip0_2496_37)">
              <path
                d="M12.5463 14.1219C13.1076 13.5583 13.4228 12.7953 13.4228 11.9999C13.4228 11.2045 13.1076 10.4415 12.5463 9.87788L2.8863 0.218883C2.33028 0.441252 1.85353 0.824938 1.51741 1.32056C1.1813 1.81618 1.0012 2.40104 1.0003 2.99988L1.00031 20.9999C1.0012 21.5987 1.1813 22.1836 1.51742 22.6792C1.85353 23.1748 2.33028 23.5585 2.88631 23.7809L12.5463 14.1219Z"
                fill="white"
              />
              <path
                d="M13.9603 8.464C14.8966 9.40263 15.4223 10.6743 15.4223 12C15.4223 13.3257 14.8966 14.5974 13.9603 15.536L5.50031 24L23.0003 24L23.0003 -7.64949e-07L5.5003 0L13.9603 8.464Z"
                fill="white"
              />
            </g>
          </svg>
        </div>
        <Content className="max-sm:hidden md:children-p:text-xl">{fields?.title}</Content>
      </button>
      {isVisible && (
        <div
          ref={formRef}
          className={clsx(
            'animate-fade-up animate-ease-in-out animate-duration-500',
            'absolute smooth bg-eo-buizel rounded-t-2xl md:rounded-b-2xl sm:p-8 p-4 sm:right-20 max-sm:left-0 max-sm:bottom-10 sm:min-w-[500px] min-w-[calc(100vw-28px)] drop-shadow-md'
          )}
        >
          <div className="flex justify-start">
            <Content className="children:uppercase children-h3:text-xl children-h3:text-white children-h3:font-black pb-5">
              {fields?.description}
            </Content>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              className="min-w-[30px] mt-2 cursor-pointer"
              onClick={closeForm}
            >
              <path
                d="M12.6312 1.65957L12.9078 1.38298L11.5248 0L6.4539 5.07093L1.65957 0.276585L1.38298 0L0 1.38298L5.16312 6.45391L0.184398 11.3404L0 11.617L1.38298 13L6.5461 7.83689L11.3404 12.7234L11.617 12.9078L13 11.617L7.83687 6.45391L12.6312 1.65957Z"
                fill="white"
              />
            </svg>
          </div>
          <Form
            renderStatus={({ status, content }) => <Content>{content}</Content>}
            className="form-newsletter"
            scrollToConfirm={false}
            data={fields?.form}
          ></Form>
        </div>
      )}
    </section>
  )
}
